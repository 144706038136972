
import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { SvoiIcon } from '@svoi-ui/components/icon'
import { SvoiButtonLink } from '@svoi-ui/components/button'
import ArticlesCard from '~/components/Cards/ArticlesCards/ArticlesCard.vue'
import type BaseRecommendationCardModel from '~/model/Cards/BaseRecommendationCardModel'

export default defineComponent({
  name: 'ArticlesSlider',
  components: { SvoiIcon, ArticlesCard, SvoiButtonLink },
  props: {
    title: {
      type: String,
      default: ''
    },
    cards: {
      type: Array as PropType<Array<BaseRecommendationCardModel>>,
      required: true
    },
    buttonTitle: {
      type: String,
      default: ''
    },
    buttonLink: {
      type: String,
      default: ''
    }
  },
  setup() {
    const swiperOptions = {
      slidesPerView: 3,
      spaceBetween: 24,
      navigation: {
        nextEl: '#button-slider-next',
        prevEl: '#button-slider-prev'
      },
      breakpoints: {
        '0': {
          slidesPerView: 'auto',
          spaceBetween: 16
        },
        '1280': {
          slidesPerView: 3,
          spaceBetween: 20
        },
        '1440': {
          slidesPerView: 3,
          spaceBetween: 24
        }
      }
    }

    return {
      swiperOptions
    }
  }
})
