
import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { SvoiIcon } from '@svoi-ui/components/icon'
import type BaseRecommendationCardModel from '~/model/Cards/BaseRecommendationCardModel'

export default defineComponent({
  name: 'ArticlesCard',
  components: { SvoiIcon },
  props: {
    card: {
      type: Object as PropType<BaseRecommendationCardModel>,
      required: true
    }
  }
})
