import { render, staticRenderFns } from "./ArticlesSlider.vue?vue&type=template&id=e904a432&scoped=true&"
import script from "./ArticlesSlider.vue?vue&type=script&lang=ts&"
export * from "./ArticlesSlider.vue?vue&type=script&lang=ts&"
import style0 from "./ArticlesSlider.vue?vue&type=style&index=0&id=e904a432&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e904a432",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SvoiIcon: require('/usr/src/app/components/SvoiUI/SvoiIcon.vue').default})
