
import { Context } from '@nuxt/types'
import { Component, Vue } from 'nuxt-property-decorator'
import LazyHydrate from 'vue-lazy-hydration'
import MainTelegramBanner from '~/components/Banners/MainTelegramBanner.vue'
import VseSvoeBanner from '~/components/Banners/VseSvoeBanner.vue'
import PartnerSwiperList from '~/components/Page/Main/PartnerSwiperList.vue'
import BaseYavagroUniversityInfo from '~/components/UI/BaseYavagroUniversityInfo.vue'
import {
  ContainerOptions,
  CourseItemModel,
  FutureProfItemModel,
  PartnerModel,
  ProfessionModel,
  WorkCardModel
} from '~/model'
import { Translation } from '~/model/Transliation'
import PersonalAPKBanner from '~/components/Page/LookingForJob/PersonalAPKBanner.vue'
import DigitalProfileBanner from '~/components/Banners/DigitalProfileBanner.vue'
import StudentActivitiesBanner from '~/components/Banners/StudentActivitiesBanner.vue'
import OlympiadBanner from '~/components/Banners/OlympiadBanner.vue'
import FinancialBanner from '~/components/Banners/FinancialBanner.vue'
import EdwicaBanner from '~/components/Banners/EdwicaBanner.vue'
import YouthCardBanner from '~/components/Banners/YouthCardBanner.vue'
import WomenAPKBannerMain from '~/components/Banners/WomenAPKBannerMain.vue'

@Component({
  name: 'home',
  middleware: ['redirect-to'],
  components: {
    WomenAPKBannerMain,
    StudentActivitiesBanner,
    OlympiadBanner,
    FinancialBanner,
    EdwicaBanner,
    DigitalProfileBanner,
    PersonalAPKBanner,
    BaseYavagroUniversityInfo,
    PartnerSwiperList,
    MainTelegramBanner,
    VseSvoeBanner,
    LazyHydrate,
    YouthCardBanner,
    MainBanner: () => import('@/components/Banners/MainBanner.vue'),
    InfiniteLine: () => import('@/components/Animations/InfiniteLine.vue'),
    WorkCardsList: () => import('@/components/Cards/WorkCards/WorkCardsList.vue'),
    HrefCardsList: () => import('@/components/Cards/HrefCards/HrefCardsList.vue'),
    FutureProfCardsList: () => import('@/components/Cards/FutureProfCards/FutureProfCardsList.vue'),
    DirectionCardsList: () => import('@/components/Cards/DirectionCards/DirectionCardsList.vue'),
    CoursesCardsList: () => import('@/components/Cards/CoursesCards/CoursesCardsList.vue'),
    ArticlesSlider: () => import('@/components/Articles/ArticlesSlider.vue'),
    PageContainer: () => import('@/components/Containers/PageContainer.vue'),
    UniversityCardsList: () => import('@/components/Cards/UniversityCards/UniversityCardsList.vue'),
    MicroBanner: () => import('@/components/Banners/MicroBanner.vue'),
    CareerMarathonBanner: () => import('@/components/Banners/CareerMarathonBanner.vue'),
    BaseRabotaAgroUniversityInfo: () =>
      import('@rshb/rshb-vue-kit/src/projects/rabotaAgro/cards/BaseRabotaAgroUniversityInfo.vue'),
    ModalContainer: () => import('~/components/Misc/ModalContainer.vue')
  }
})
export default class MainPage extends Vue {
  async asyncData({ $services, $tstore, route }: Context) {
    await Promise.all([
      $services.newsRecommendationsService
        .getNewsRecommendationsLatest()
        .then(newsRecommendationsResponse => $tstore.news.setNewsRecommendationsList(newsRecommendationsResponse)),

      $services.requestRest
        .getList<CourseItemModel>('courses', { ids: $tstore.pageMetadata.getSeoMetadata.blocks?.courses_filter })
        .then(data => $tstore.course.setCourseList(data.data)),

      $services.requestRest
        .getList<ProfessionModel>('professions', {
          ids: $tstore.pageMetadata.getSeoMetadata.blocks?.infinite_line_items_filter
        })
        .then(data => $tstore.professions.setProfessionsList(data.data)),

      $services.workCards.getWorkCardsList<WorkCardModel[]>().then(data => $tstore.workCards.setWorkCardsList(data)),

      $services.partner.getPartnerListMain<PartnerModel[]>().then(data => $tstore.partner.setPartnerList(data)),

      $services.futureProf
        .getFutureProfList<FutureProfItemModel[]>()
        .then(data => $tstore.futureProf.setFutureProfList(data)),

      $services.requestRest
        .getTotalItemsCount('universities')
        .then(data => $tstore.university.setCountUniversity(data)),

      $services.requestRest.getTotalItemsCount('colleges').then(data => $tstore.college.setCountCollege(data)),

      $services.requestRest
        .getTotalItemsCount('professional-education')
        .then(data => $tstore.professionalEducation.setCountProfessionalEducation(data)),
      $services.requestRest
        .getItem<Translation>('translations', 'karernyy-marafon-2023')
        .then(translation => $tstore.marathon.setTranslation(translation))
    ])

    const backUrl = route.query.backUrl
    if (backUrl !== undefined) {
      $tstore.auth.setShowModalAuth({ show: true, backUrl: backUrl.toString() })
    }
  }

  get articleRecommendations() {
    return this.$tstore.news.newsRecommendationsList
  }

  get getShowMarathonBanner() {
    return this.translation && this.translation.is_published && false
  }

  get isCompany(): boolean {
    const authorizedUser = this.$tstore.auth.authorizedUser
    return authorizedUser?.roles.has('company') ?? false
  }

  get translation() {
    return this.$tstore.marathon.Translation
  }

  containerOptions: ContainerOptions = {
    name: 'Выбери будущую профессию',
    button: {
      text: 'Все профессии будущего',
      href: '/professions',
      icon: 'readmore'
    },
    highlightedBackground: true
  }

  telegramBanner = {
    id: 1,
    description: 'Там мы публикуем актуальные новости и быстрее отвечаем на ваши вопросы',
    name: 'Подписывайся на наш канал в Telegram',
    buttonName: 'Подписаться',
    image: '/icons/misc/fly.png',
    imageMobile: '/icons/misc/flyMobile.svg',
    theme: 'blue'
  }

  directionCards = [
    {
      id: 'direction1',
      name: 'Сельское хозяйство',
      background: 'linear-gradient(180deg, #CFFDD9 0%, #E1FFDE 100%)',
      image: '/images/card-icons/shovel.png',
      href: '/directions/selskoe-khozyaystvo'
    },
    {
      id: 'direction2',
      name: 'Биотехнологии',
      background: 'rgba(255, 205, 169, 1)',
      image: '/images/card-icons/microscope.png',
      href: '/directions/biotekhnologii'
    },
    {
      id: 'direction3',
      name: 'Экология',
      background: 'linear-gradient(130.5deg, #FDE2CF -28.36%, #FFFEDE 113.83%)',
      image: '/images/card-icons/flask.png',
      href: '/directions/ekologiya'
    },
    {
      id: 'direction4',
      name: 'Робототехника',
      background: 'linear-gradient(97.65deg, #FBF6CD 0%, #ECFBCD 100%), #F6FAD1',
      image: '/images/card-icons/battry.png',
      href: '/directions/robototekhnika'
    },
    {
      id: 'direction5',
      name: 'ИТ-сектор',
      background: 'rgba(207, 242, 253, 1)',
      image: '/images/card-icons/usb.png',
      href: '/directions/it-sektor'
    },
    {
      id: 'direction6',
      name: 'Наука',
      background: 'linear-gradient(130.5deg, #CFCFFD -28.36%, #DEE3FF 113.83%)',
      image: '/images/card-icons/pendulum.png',
      href: '/directions/nauka'
    }
  ]

  microBanners = [
    {
      id: 1,
      name: 'Школа фермера',
      description: 'Пройдите путь фермера и создайте своё фермерское хозяйство',
      buttonName: 'Начать учиться',
      url: '/schoolfarmers',
      theme: 'green',
      image: '/images/main/micro-banner-3.png'
    },
    {
      id: 2,
      name: 'Агротека',
      description:
        'Увлекательные подкасты, интервью и видео-обзоры об образовательных учреждениях и компаниях в сфере АПК',
      buttonName: 'Смотреть',
      url: '/news',
      theme: 'blue',
      image: '/images/main/micro-banner-4.png'
    }
  ]

  goToTelegram() {
    window.open('https://t.me/yavagro', '_blank')
  }
}
